import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import BodyText from '@components/bodyText';
import Contact from '../../../components/contact';
import Hero from '../../../components/hero';
import Layout from '@components/layout';

const AllmannaVillkorFaktura = ({ data }) => {
  const headLine = data.contentfulPage.headLine;
  const bodyText = data.contentfulPage.pageContent[2].bodyText;

  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/allmanna-villkor', name: 'Allmänna Villkor' },
          { path: '/allmanna-villkor/blocket', name: 'Blocket' },
        ]}
        title="Faktura / Delbetalning"
      />
      <div id="first">
        <BodyText bodyText={bodyText} />
      </div>
      <Contact hideFAQ />
    </Layout>
  );
};

export default AllmannaVillkorFaktura;

AllmannaVillkorFaktura.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query AllmannaVillkorFakturaQuery {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor" }) {
      headLine

      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
